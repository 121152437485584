import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import modules from "./modules";

Vue.use(Vuex);

const state = {
  loading: false,
};

const mutations = {
  loading: (state, data) => {
    state.loading = data;
  },
};

export default new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV !== "production",
  state,
  mutations,
  plugins: [
    createPersistedState({
      key: "printer",
      paths: ["auth"],
    }),
  ],
});
