import _axios from "axios";
import store from "../store";
export const API_BASE = process.env.VUE_APP_API_BASE;

export const axios = _axios.create({
  baseURL: API_BASE,
  headers: {
    Accept: "application/json",
  },
});

export default () => {
  axios.interceptors.request.use(async (config) => {
    if (store.state.auth.token) {
      config.headers["Authorization"] = "Token " + store.state.auth.token;
    }
    config.headers["Access-Control-Allow-Origin"] = "*";
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return {
    auth: {
      login: (params) => axios.post("/api/authtoken/", params),
      getUserDetail: (username) => axios.get(`api/user/${username}`),
    },
    dashboard: {
      getArticle: (id) => axios.get(`/sustainability/articles/${id}/`),
    },
  };
};
