<template>
  <v-app>
    <router-view></router-view>
    <Navbar />
    <Footer />
  </v-app>
</template>

<script>
import Footer from "./components/Footer.vue";
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",
  components: { Navbar, Footer },
  data: () => ({
    //
  }),
};
</script>
