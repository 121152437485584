<template>
  <div class="footer">
    <v-container>
      <v-row class="text-center">
        <v-col md="7" lg="8" xs="12">
          <div class="footer-logo">
            <img src="@/assets/images/bionix.svg" alt="logo" />
          </div>
        </v-col>
        <v-col xs="12" md="5" lg="4" class="text-start align-self-center">
          <p class="mb-1" :class="$vuetify.breakpoint.smAndDown ? 'mb-0' : ''">
            Get the freshest news from us
          </p>
          <v-row
            class="d-flex flex-row"
            :class="$vuetify.breakpoint.smAndDown ? 'flex-col' : ''"
          >
            <v-col cols="12" xs="12" md="6">
              <input type="text" class="input" />
            </v-col>
            <v-col cols="12" xs="12" md="6">
              <v-btn color="primary" :block="$vuetify.breakpoint.smAndDown"
                >Subscribe</v-btn
              ></v-col
            >
          </v-row>
        </v-col>
      </v-row>

      <v-divider style="margin: 0.8rem 0"></v-divider>

      <v-row>
        <v-col
          xs="12"
          md="6"
          class="d-flex"
          :class="$vuetify.breakpoint.smAndDown ? 'center' : ''"
        >
          <p class="footer-links">Privacy Policy</p>
          <p class="footer-links">Accessibility</p>
          <p class="footer-links">Legal</p>
        </v-col>
        <v-col xs="12" md="6">
          <p class="copyright-text">
            Bionix Supplychain Technologies SL &copy; 2021. All rights reserved
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  mounted() {},
  data: () => ({
    navigation: [
      { name: "Brand", route: "" },
      { name: "Care", route: "" },
      { name: "Material", route: "" },
      { name: "Production", route: "" },
      { name: "Extend the Life", route: "" },
      { name: "Your Feedback", route: "" },
    ],
  }),
};
</script>
