import Vue from "vue";
import VueRouter from "vue-router";
// import store from "../store";
// import adminRoutes from "./modules/admin";

Vue.use(VueRouter);

// const beforeEnter = (_to, _from, next) => {
//   if (_to.meta.auth) {
//     if (store.state.auth.token) {
//       next();
//     } else {
//       next("/");
//     }
//   } else {
//     next();
//   }
// };

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/admin/Dashboard.vue"),
  },
  {
    path: "/:id",
    name: "home",
    component: () => import("@/views/Home.vue"),
  },
  // {
  //   path: "/",
  //   name: "login",
  //   component: () => import("@/views/Login.vue"),
  // },
  // {
  //   path: "/admin",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "admin" */ "@/views/_layouts/AdminLayout.vue"
  //     ),
  //   beforeEnter: beforeEnter,
  //   children: adminRoutes,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
export default router;
