<template>
  <div class="home-page">
    <v-container>
      <v-tabs
        v-model="tab"
        class="nav-item"
        background-color="transparent"
        grow
      >
        <v-tab v-for="(item, index) in items" :key="index">
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(item, index) in items"
          :key="index"
          class="nav-description"
        >
          <v-card flat v-if="articles">
            <h1 class="title text-uppercase">{{ item.name }}</h1>
            <v-row
              v-for="(tab, index) in tabDescripton(item.name)"
              :key="index"
              class="d-flex"
              :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
            >
              <v-col cols="12" xs="12" sm="2" v-if="tab.img">
                <img :src="tab.img" :alt="item.name" class="tab-icon" />
              </v-col>
              <v-col cols="12" xs="12" :sm="tab.img ? 10 : 12">
                <v-card-text class="item-text">
                  {{ tab.description || "" }}
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Navbar",
  mounted() {
    // this.fetchArticles();
  },
  data: () => ({
    tab: null,
    items: [
      { name: "Brand", route: "" },
      { name: "Care", route: "" },
      { name: "Material", route: "" },
      { name: "Production", route: "" },
      { name: "Extend the Life", route: "" },
      { name: "Your Feedback", route: "" },
    ],
  }),
  methods: {
    ...mapActions({
      fetchArticles: "dashboard/fetchArticles",
    }),
    tabDescripton(tab) {
      if (this.articles) {
        return {
          Brand: [
            {
              ...this.articles?.brand,
              description: this.articles?.brand?.description,
              img: this.articles?.brand?.img,
            },
          ],
          Production: [
            {
              description: this.articles?.made_in?.factory_description,
            },
          ],
          Material: this.articles?.material?.map((item) => ({
            ...item.material,
            percent: item.percent,
          })),
          Care: [
            ...(this.articles?.washing || []),
            ...(this.articles?.ironing || []),
            ...(this.articles?.drying || []),
          ]?.map((item) => ({ ...item, img: item?.icon, name: "care" })),
        }[tab];
      } else {
        return "";
      }
    },
  },
  computed: {
    ...mapGetters({
      articles: "dashboard/getArticle",
    }),
  },
};
</script>
