import Vue from "vue";

const state = {
  articles: [],
};

const mutations = {
  SET_ARTICLES: (state, payload) => {
    state.articles = payload[0];
  },
};

const actions = {
  fetchArticles({ commit }, id) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.dashboard
        .getArticle(id)
        .then(({ data }) => {
          commit("SET_ARTICLES", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {
  getArticle: (state) => state.articles,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
