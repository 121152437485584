import "material-design-icons-iconfont/dist/material-design-icons.css";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import Vue from "vue";
import App from "./App.vue";
import "@/assets/styles/app.scss";
import vuetify from "./plugins/vuetify";
import router from "./router";
import Api from "./services/";
import store from "./store";

Vue.config.productionTip = false;

Vue.use(Api, { store });

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
